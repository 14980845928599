import * as types from './types'
import { product } from '../../tools/api'
import { beginApiCall, apiCallError } from './apiStatusActions'

let alerted = false;

export const loadProductSuccess = product => {
  return { type: types.LOAD_PRODUCT_SUCCESS, product }
}

export const loadProduct = (sku, params, history) => {
  const language = params.language || 'en';
  return dispatch => {
    dispatch(beginApiCall());
    return product
      .getProduct(sku, params)
      .then(({ json: product }) => {
        if (!product) {
          if (!alerted) {
            if (language === 'fr') {
              alert('Pas de résultat pour le produit ' + sku);
            } else {
              alert('No result for product ' + sku);
            }
            alerted = true;  // To prevent duplicate alert until duplicate api call resolved
            history.push(`/${language}/catalog`);
          }
        } else {
          dispatch(loadProductSuccess(product));
        }
      })
      .catch(error => {
        dispatch(apiCallError(error))
        throw error
      })
  }
}
