import React from 'react';
import { Link } from 'react-router-dom';
import { colors, spacing } from '../../../../defaults/styles';

import { Translate, withLocalize } from 'react-localize-redux';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';

const UL = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-top: ${spacing.small}px;
  padding: ${spacing.small}px ${spacing.medium}px ${spacing.medium}px
    ${spacing.medium}px;
  width: 100%;
`;

const LI = styled.li`
  padding: ${spacing.small}px;
  margin-bottom: ${spacing.verySmall};
  text-align: center;
  width: 100%;
`;

const LoginFooter = ({isSample, activeLanguage}) => {
  const lang = activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';
  const t = (tag) => `login.footer.${tag}`;

  return (
    <UL>
      {!isSample && (
      <LI>
        <Link 
          to={'/forgot-password'} 
          style={{ color: colors.gray.normal }}
          onClick={(e) => { e.preventDefault(); window.location.href = '/'+lang+'/forgot-password' }}
        >
          <Translate id={t('forgotPassword')} />
        </Link>
      </LI>)}
      
      <LI>
        <Link 
          to={'/contact-us'} 
          style={{ color: colors.gray.normal }}
          onClick={(e) => { e.preventDefault(); window.location.href = '/'+lang+'/account-request' }}
        >
          <Translate id={t('requestAccount')} />
        </Link>
      </LI>
    </UL>
  );
};

export default withLocalize(LoginFooter);
