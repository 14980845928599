import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import MainHeader from '../components/presentation/layout/MainHeader';
import MainFooter from '../components/presentation/layout/MainFooter';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import {
  HomePage,
  AboutUsPage,
  ServicesRewardsPage,
  ServicesFulfilmentPage,
  ServicesGiftingPage,
  OrdersPage,
  EnvironmentPage,
  CheckoutPage,
  ProductPage,
  BrandsPage,
  LoginPage,
  ContactUsPage,
  ContactBriefPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  PrivacyPage,
  TermsAndConditionsPage,
  LogoutPage,
  LoggedInHomePage,
  UserInfoPage,
  CatalogPage,
  BlogPostsPage,
  NewsEntriesPage,
  AccountRequestPage,
  BrandPartnersPage,
  ThankYouPage,
  CorporateGiftsPage,
  VendorAgreementPage,
  VendorAgreementSuccessPage,
  BrandRedirect,
} from '../components/presentation/pages';
import { renderToStaticMarkup } from 'react-dom/server';
import { withLocalize } from 'react-localize-redux';
import ErrorPage from '../components/presentation/pages/ErrorPage';
import ResizeAwareContainer from '../components/smart/ResizeContext';
import BlogPost from '../components/presentation/pages/BlogPostsPage/BlogPostPage';
import globalLexicon from './global-lexicon';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { lsStyled as styled } from './../tools/helpers/lsStyled';

const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
    body {
        font-family: 'Roboto', sans-serif;
    }
`;

class App extends React.Component {
  constructor(props) {
    super(props);

    // Extract the pathname from props.location
    const { pathname } = props.location;

    // Determine the language. If lang not in path, check locale, then browser settings.
    let lang;
    if (pathname.substring(0, 3) !== "/en" && pathname.substring(0, 3) !== "/fr") {
      let storageLocale = localStorage.getItem('locale');

      if (storageLocale === 'fr' || storageLocale === 'en') {
        lang = storageLocale;
      } else {
        if (navigator.language.startsWith("fr")) {
          lang = 'fr'
        } else {
          lang = 'en';
        }
      }
    } else {
      lang = pathname.substring(1, 3);
    }

    localStorage.setItem('locale', lang);
    this.state = {
      lang: lang,
    };

    props.initialize({
      languages: [
        { name: 'English', code: 'en' },
        { name: 'Français', code: 'fr' },
      ],
      translation: globalLexicon,
      options: { renderToStaticMarkup },
    });
}

componentDidMount() {
  const { activeLanguage, setActiveLanguage } = this.props;
  const { lang } = this.state;

  // Ensure the active language is set correctly
  if (!activeLanguage || activeLanguage.code !== lang) {
    setActiveLanguage(lang);
  }
}

  render() {
    const { user: { loggedIn }, location, history, activeLanguage, setActiveLanguage } = this.props;
    const { lang } = this.state;
    const isLoginPage = window.location.href.includes('login');
    const selectedLang = lang;
    return (
      <ResizeAwareContainer>
        <GlobalStyle />

        {!isLoginPage && <MainHeader />}

        <AnimationWrapper>
          <TransitionGroup>
            <CSSTransition
              key={location.key}
              timeout={{ enter: 300, exit: 150 }}
              classNames={'fade'}
            >
              <Switch location={location}>
                <Route exact path='/earthfriendly'>
                  <Redirect to='/en/environment' />
                </Route>
                <Route exact path='/amidelaplanete'>
                  <Redirect to='/fr/environment' />
                </Route>
                <Route exact path={['/en', '/fr']} component={loggedIn ? LoggedInHomePage : HomePage} />

                {/* Handle language-specific routes */}
                <Route path={['/en/*', '/fr/*']} render={({ match }) => {
                  // If you add a new route, you also have to add its name in the validPaths array, else it will redirected to the homepage
                  const validPaths = [
                    "info",
                    "about-us",
                    "services-rewards",
                    "services-fulfilment",
                    "services-gifting",
                    "brands",
                    "catalog",
                    "product",
                    "blog-list",
                    "news-list",
                    "blog",
                    "news",
                    "orders",
                    "checkout",
                    "environment",
                    "login",
                    "logout",
                    "contact-us",
                    "contact-brief",
                    "account-request",
                    "brand-partners",
                    "thank-you",
                    "corporate-gifts",
                    "vendor-agreement",
                    "vendor-agreement-success",
                    "forgot-password",
                    "reset-password",
                    "privacy-policy",
                    "terms-and-conditions",
                    "error"
                  ];

                  const subPath = match.params[0].split('/')[0];
                  const langPrefix = match.params.lang;

                  if (!validPaths.includes(subPath)) {
                    return <Redirect to={`/${langPrefix}`} />;
                  }

                  // Render the appropriate component for valid paths
                  return (
                    <>
                      <Switch>
                        <Route path={`/:lang/info`} component={loggedIn ? UserInfoPage : HomePage} />
                        <Route path={`/:lang/about-us`} component={AboutUsPage} />
                        <Route path={`/:lang/services-rewards`} component={ServicesRewardsPage} />
                        <Route path={`/:lang/services-fulfilment`} component={ServicesFulfilmentPage} />
                        <Route path={`/:lang/services-gifting`} component={ServicesGiftingPage} />
                        <Route exact path={`/:lang/brands`} component={BrandsPage} />
                        <Route path={`/:lang/catalog`} component={CatalogPage} />
                        <Route
                          path="/:lang/product/:sku?"
                          render={props => {
                            const { match } = props;
                            return match.params.sku ? (
                              <ProductPage {...props} />
                            ) : (
                              <Redirect to={`/${selectedLang}`} />
                            );
                          }}
                        />
                        <Route path={`/:lang/blog-list`} component={BlogPostsPage} />
                        <Route path={`/:lang/news-list`} component={NewsEntriesPage} />
                        <Route
                          path="/:lang/blog/:id?"
                          render={props => {
                            const { match } = props;
                            return match.params.id ? (
                              <BlogPost {...props} />
                            ) : (
                              <Redirect to={`/${selectedLang}`} />
                            );
                          }}
                        />
                        <Route path={`/:lang/orders`} component={loggedIn ? OrdersPage : LoginPage} />
                        <Route path={`/:lang/checkout`} component={loggedIn ? CheckoutPage : LoginPage} />
                        <Route path={`/:lang/environment`} component={EnvironmentPage} />
                        <Route path={`/:lang/login`} component={loggedIn ? LoggedInHomePage : LoginPage} />
                        <Route path={`/:lang/logout`} component={loggedIn ? LogoutPage : HomePage} />
                        <Route path={`/:lang/contact-us`} component={ContactUsPage} />
                        <Route path={`/:lang/contact-brief`} component={ContactBriefPage} />
                        <Route path={`/:lang/account-request`} component={AccountRequestPage} />
                        <Route path={`/:lang/brand-partners`} component={BrandPartnersPage} />
                        <Route path={`/:lang/thank-you`} component={ThankYouPage} />
                        <Route path={`/:lang/corporate-gifts`} component={CorporateGiftsPage} />
                        <Route
                          path="/:lang/vendor-agreement/:token?"
                          render={props => {
                            const { match } = props;
                            return match.params.token ? (
                              <VendorAgreementPage {...props} />
                            ) : (
                              <Redirect to={`/${selectedLang}`} />
                            );
                          }}
                        />
                        <Route path={`/:lang/vendor-agreement-success`} component={VendorAgreementSuccessPage} />
                        <Route path={`/:lang/forgot-password`} component={ForgotPasswordPage} />
                        <Route path={`/:lang/reset-password`} component={ResetPasswordPage} />
                        <Route path={`/:lang/privacy-policy`} component={PrivacyPage} />
                        <Route path={`/:lang/terms-and-conditions`} component={TermsAndConditionsPage} />
                        <Route
                          path="/:lang/brands/:brandname?"
                          render={props => {
                            const { match } = props;
                            return match.params.brandname ? (
                              <BrandRedirect {...props} />
                            ) : (
                              <Redirect to={`/${selectedLang}/brands`} />
                            );
                          }}
                        />
                        <Route path={`/:lang/error`} component={ErrorPage} />
                      </Switch>
                    </>
                  );
                }} />

                {/* Fallback route: redirect to homepage for any unmatched route */}
                <Route
                  path="*"
                  render={() => {
                    const selectedLang = localStorage.getItem('locale') || 'en';
                    return <Redirect to={`/${selectedLang}`} />;
                  }}
                />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
        </AnimationWrapper>

        {!isLoginPage && <MainFooter />}
        <ToastContainer autoClose={3000} hideProgressBar />
      </ResizeAwareContainer>
    );
  }
}

const AnimationWrapper = styled.div`
  .fade-appear,
  .fade-enter {
    opacity: 0;
    z-index: 1;
  }
  .fade-appear-active,
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms linear;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit.fade-exit-active {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 150ms linear;
  }
`;

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps, null)(withLocalize(withRouter(App)));
