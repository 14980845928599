import React from 'react';
import styled from 'styled-components';

import PageReinforcementStatic from './../../interface/PageReinforcementStatic';
import UIContainer from './../../interface/UIContainer';
import UISection from './../../interface/UISection';
import UIGrid2cols from './../../interface/UIGrid2cols';
import UIParagraph from './../../interface/UIParagraph';
import { Translate, withLocalize } from 'react-localize-redux';

// == Styling == //
const ImageBox = styled.div`
  max-width: 450px;
  width: 100%;
  order: ${props => (props.isMobile || props.isTablet ? 2 : null)};
`;

// == /Styling == //

const AffordableService = ({ isMobile, isTablet, translate }) => {
  const t = (tag) => `services.fulfillment.affordableServices.${tag}`;

  return (
    <UIContainer
      isMobile={isMobile}
      isTablet={isTablet}
      customStyle={{ marginTop: isMobile ? 0 : '2em', marginBottom: '3em' }}
    >
      <UIGrid2cols
        isMobile={isMobile}
        isTablet={isTablet}
        mobileOrderReverse={true}
      >
        <ImageBox isMobile={isMobile} isTablet={isTablet}>
          <img
            src={'/images/pages/services-fulfilment/warehouse.png'}
            alt="temp"
            style={{ maxWidth: isMobile ? '100%' : 450 }}
          />
        </ImageBox>
        <UISection isMobile={isMobile} isTablet={isTablet}>
          <PageReinforcementStatic
            title={translate(t('title'))}
            subTitle={translate(t('subTitle'))}
            borderRequired={true}
            heading={'h2'}
            type={'2'}
          />
          <UIParagraph>
            <Translate id={t('mainParagraph')} />
          </UIParagraph>
        </UISection>
      </UIGrid2cols>
    </UIContainer>
  );
};

export default withLocalize(AffordableService);
