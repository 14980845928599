import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { loadProduct } from '../../../redux/actions/productActions';
import { withLocalize } from 'react-localize-redux';
import { setSelectedCategory } from '../../../redux/actions/selectedCategoryActions';
import { withRouter } from 'react-router-dom';

export const ProductContainer = ({
  history,
  loadProduct,
  sku,
  activeLanguage,
  setSelectedCategory,
  ...props
}) => {
  const [product, setProduct] = useState({});
  const [previousLanguage, setPreviousLanguage] = useState();

  useEffect(() => {
    if (activeLanguage) {
      const language =
        activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';
      if (props.product.sku !== sku || previousLanguage !== language) {
        setPreviousLanguage(language);
        loadProduct(sku, { language }, history);
      } else {
        setSelectedCategory({
          topId: props.product.categoryGroupId,
          catId: props.product.categoryId,
          typeId: props.product.categorySpecificationId,
        });
        setProduct(props.product);
      }
    }
  }, [props.product, loadProduct, sku, activeLanguage, history]);

  const childrenWithProps = React.Children.map(props.children, child =>
    React.cloneElement(child, { product })
  );
  return <>{childrenWithProps}</>;
};

ProductContainer.propTypes = {
  loadProduct: propTypes.func.isRequired,
  product: propTypes.object.isRequired,
};

function mapStateToProps({ product }, ownProps) {
  const sku = ownProps.match.params.sku;
  return {
    product,
    sku,
  };
}

const mapDispatchToProps = {
  loadProduct,
  setSelectedCategory,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(withRouter(ProductContainer)));
