import React, { useContext } from 'react';
import styled from 'styled-components';
import UIMain from './../../interface/UIMain';
import UIContainer from './../../interface/UIContainer';
import UIParagraph from './../../interface/UIParagraph';
import PageReinforcementStatic from './../../interface/PageReinforcementStatic';
import qs from 'querystring';
import { Translate, withLocalize } from 'react-localize-redux';
import { ResizeAwareContext } from '../../../smart/ResizeContext';

const MessageBox = styled.div`
  max-width: 48em;
  /* workaround to push footer down, not sticky footer */
  margin: 2em auto 10em auto;
`;

const ErrorPage = ({ translate, ...props }) => {
  const t = (tag) => `error.${tag}`;

  const { isMobile, isTablet } = useContext(ResizeAwareContext);

  const error = qs.parse(props.location.search.substr(1));

  document.title = translate(t('title')) + (error.status ? ' ' + error.status : '');

  return (
    <UIMain isMobile={isMobile} isTablet={isTablet}>
      <UIContainer isMobile={isMobile} isTablet={isTablet}>
        <PageReinforcementStatic
          title={translate(t('header'))}
          subTitle={translate(t('description'))}
          alignment={'center'}
          heading={'h1'}
          type={2}
          isMobile={isMobile}
          isTablet={isTablet}
        />
        {/* <MessageBox>
          <UIParagraph customStyle={{ marginBottom: 0 }}>
            <Translate id={t('code')} /> {error.status}
          </UIParagraph>
          <UIParagraph overflow={true}>
            <Translate id={t('message')} /> {error.message}
          </UIParagraph>
        </MessageBox> */}
      </UIContainer>
    </UIMain>
  );
};

export default withLocalize(ErrorPage);
