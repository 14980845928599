import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { brand } from '../../../../tools/api';
let alerted = false;

const BrandRedirect = () => {
  const { lang, brandname } = useParams();
  const history = useHistory();

  useEffect(() => {
    const getBrandId = async () => {
      try {
        const response = await brand.getBrandId(brandname);
        const brandId = response.json;
        if (brandId) {
          history.replace(`/${lang}/catalog?brand=${brandId}`);
        } else {
          if (!alerted) {
            if (lang === 'fr') {
              alert('Pas de résultat pour la marque ' + brandname);
            } else {
              alert('No result for brand ' + brandname);
            }
            alerted = true;  // To prevent duplicate alert until duplicate api call resolved
            history.replace(`/${lang}/brands`);
          }
        }
      } catch (error) {
        alert('Error fetching brand information');
        history.replace(`/${lang}/brands`);
      }
    };

    getBrandId();
  }, [brandname, lang, history]);

  return null;
};

export default BrandRedirect;