import React from 'react';
import Radium from 'radium';
import Product from './Product';
import UIMain from '../../interface/UIMain';
import UISection from '../../interface/UISection';
import { ProductContainer } from '../../../smart';
import { sizing, spacing } from '../../../../defaults/styles';
import CategoriesSideBar from '../../layout/CategoriesSideBar';
import { ResizeAwareConsumer } from '../../../smart/ResizeContext';
import { withLocalize } from 'react-localize-redux';

const categoriesAsideWidth = sizing.categoriesAsideWidth;

const ProductPage = ({ history, match }) => {
  var fromCorporateGiftsPage = false;
  if (history && history.location && history.location.state && history.location.state.from) {
    fromCorporateGiftsPage = history.location.state.from === 'corporate-gifts';
  }
  return (
    <ResizeAwareConsumer>
      {({ isMobile, isTablet }) =>
      <UIMain isMobile={isMobile} isTablet={isTablet}>
          <UISection
            noPadding
            isMobile={isMobile}
            isTablet={isTablet}
            customStyle={isMobile
              ? {}
              : !fromCorporateGiftsPage
                ? {
                    display: 'grid',
                    gridTemplateColumns: `${categoriesAsideWidth}px 1fr`,
                    marginTop: spacing.marginStandard,
                    ...sizing.boundToContentWidth,
                  }
                : {
                    display: 'block',
                    marginTop: spacing.marginStandard,
                    ...sizing.boundToContentWidth,
                  }
            }
          >
          <ProductContainer match={match}>
          {!fromCorporateGiftsPage && (
                <CategoriesSideBar 
                  history={history} 
                  hideFilters={true} 
                />
              )}
            <Product history={history} />
          </ProductContainer>
        </UISection>
      </UIMain>
      }
    </ResizeAwareConsumer>
  );
};

export default withLocalize(Radium(ProductPage));
