import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { loadCatalogForCorporateGifts } from '../../../redux/actions/catalogActions';
import qs from 'querystring';
import { withLocalize } from 'react-localize-redux';
import { loadBrands } from '../../../redux/actions/brandActions';
import { loadProductFeatures } from '../../../redux/actions/featureActions';
import { loadListing } from '../../../redux/actions/featureProductsActions';

export const CorporateGiftsContainer = ({
    user,
    apiCallsInProgress,
    activeLanguage,
    loadCatalogForCorporateGifts,
    catalogForCorporateGifts,
    history,
    loadBrands,
    loadProductFeatures,
    categories,
    listing,
    loadListing,
    shoppingLists,
    ...props
}) => {
    const [brands, setBrands] = useState([]);

    const param = qs.parse(history.location.search.substr(1));
    param.language = activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';

    useEffect(() => {
        if (props.brands.length === 0) loadBrands(param.language);
        else setBrands([...props.brands]);
    }, [props.brands, loadBrands, props.features]);

    useEffect(() => {
        if (activeLanguage) {
            loadListing(param);
        }
    }, [history.location, props.listing, activeLanguage]);

    useEffect(() => {
        if (activeLanguage) {
            loadCatalogForCorporateGifts(param, 0);
        }
    }, [history.location, activeLanguage]);

    const totalPages = catalogForCorporateGifts[0] ? catalogForCorporateGifts[0].totalPages : 0;
    const totalElements = catalogForCorporateGifts[0] ? catalogForCorporateGifts[0].totalElements : 0;
    const childrenWithProps = React.Children.map(props.children, child =>
        React.cloneElement(child,
            { user, apiCallsInProgress, catalogForCorporateGifts, loadCatalogForCorporateGifts, history, totalPages, totalElements, brands, categories, listing, shoppingLists }
        )
    );

    return <>{childrenWithProps}</>;
};

CorporateGiftsContainer.propTypes = {
    user: propTypes.object.isRequired,
    apiCallsInProgress: propTypes.number.isRequired,
    catalogForCorporateGifts: propTypes.array.isRequired,
    loadCatalogForCorporateGifts: propTypes.func.isRequired,
    loadBrands: propTypes.func.isRequired,
    brands: propTypes.array.isRequired,
    features: propTypes.array.isRequired,
    loadListing: propTypes.func.isRequired,
    listing: propTypes.array.isRequired
};

function mapStateToProps({ user, catalogForCorporateGifts, apiCallsInProgress, brands, listing, shoppingLists, features }) {
    return { user, catalogForCorporateGifts, apiCallsInProgress, brands, listing, shoppingLists, features };
}

const mapDispatchToProps = {
    loadCatalogForCorporateGifts,
    loadBrands,
    loadProductFeatures,
    loadListing,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(CorporateGiftsContainer));